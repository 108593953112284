import React from 'react'
import ReactDOM from 'react-dom';

import {Fetcher} from '../../fetcher';
import WebformBuilder from './WebformBuilder.jsx';
import Spinner from '../Spinner.jsx';
import * as CONSTANTS from '../../Utils/constants';

const yaml = require('js-yaml');

class WebformWrapper extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      elements : {},
      status : CONSTANTS.APP_STATUS.LOADING,
      submitted : false,
      errorMessages : {},
      values : {},
    };

    this.errorTitle = React.createRef();
  }

  componentDidMount() {
    let elements = yaml.safeLoad(this.props.elements);

    this.setState({
      elements : elements,
      status : CONSTANTS.APP_STATUS.LOADED,
    });
  }

  componentDidUpdate() {
    if (
      this.errorTitle
      && this.errorTitle.current
    ) {
      this.errorTitle.current.focus();

      window.scrollTo({
        top:
          // Position Y of element
          this.errorTitle.current.getBoundingClientRect().top
          // Position Y of scroll
          + window.pageYOffset
          // Offset of menu
          - 130,
        behavior: 'smooth',
      });
    }
  }

  handleSubmit = (formParams) => {
    this.setState(
      {
        status : CONSTANTS.APP_STATUS.LOADING,
      },
      this._sendDataToApi(formParams)
    );
  };

  _sendDataToApi = (formParams) => {
    try {
      let postArgs = {
        webform_id : this.props.id,
        values : formParams,
      };

      Fetcher.request('/webform/', 'post', postArgs).then((response) => {
        if (
          typeof response.status !== 'undefined'
          && response.status == 'success'
          && typeof response.query !== 'undefined'
          && typeof response.query.sid !== 'undefined'
        ) {
          this.setState({
            status : CONSTANTS.APP_STATUS.LOADED,
            values : formParams,
            submitted : true,
            errorMessages : {},
          });
        }
        else {
          this.setState({
            status : CONSTANTS.APP_STATUS.ERROR,
            values : formParams,
            errorMessages : typeof response.query !== 'undefined'
              && typeof response.query.error !== 'undefined'
              ? response.query.error
              : {unknown : 'Unknown error'},
          });
        }
      });
    }
    catch (error) {
      this.setState({
        status : CONSTANTS.APP_STATUS.ERROR,
        values : formParams,
        errorMessages : {error : error.message || 'Unknown error'},
      });
    }
  };

  _renderErrors = () => {
    if (this.state.status !== CONSTANTS.APP_STATUS.ERROR) {
      return '';
    }

    return (
      <div className={'alert alert-danger'}>
        <p><strong tabIndex={0} ref={this.errorTitle}>Erreur !</strong></p>
        <ul className={'simple'}>
          {
            Object.keys(this.state.errorMessages).map(key => {
              return (
                <li dangerouslySetInnerHTML={{__html: this.state.errorMessages[key]}}></li>
              );
            })
          }
        </ul>
      </div>
    );
  };

  render() {
    if (this.state.submitted) {
      return (
        <div className={'success--state'}>
          <i className={'icon-paper-plane'}>&nbsp;</i>
          <h1>Votre message a été envoyé</h1>
          <p>Nous vous répondrons dans les plus brefs délais</p>
        </div>
      )
    }
    else if (
      this.state.status === CONSTANTS.APP_STATUS.LOADED
      || this.state.status === CONSTANTS.APP_STATUS.ERROR
    ) {
      return (
        <>
          {this._renderErrors()}
          <WebformBuilder
            elements={this.state.elements}
            prefix={this.props.prefix}
            prefill={JSON.parse(this.props.prefill)}
            handleSubmit={this.handleSubmit}
            errors={this.state.errorMessages}
            values={this.state.values}/>
        </>
      )
    }
    else {
      return (
        <div className={'col-xs-12 col-sm-12 col-md-12 col-g10'}>
          <Spinner/>
        </div>
      );
    }
  }
}

export default WebformWrapper

const domContainer = document.querySelector('.form__wrapper');

if (domContainer) {
  const id = domContainer.getAttribute('data-id');
  const elements = domContainer.getAttribute('data-elements');
  const prefix = domContainer.getAttribute('data-prefix');
  const prefill = domContainer.getAttribute('data-prefill');

  ReactDOM.render(<WebformWrapper id={id} elements={elements} prefix={prefix} prefill={prefill}/>, domContainer);
}
