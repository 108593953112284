import React from 'react';

export default class ViewmodeSearch extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <article className={'resultsearch--result'}>
        <a className={'resultsearch__bloc'} target={'_blank'} href={this.props.url}>
          <div className={'resultsearch__title'}>{this.props.title}</div>
          <div className={'resultsearch__summary'} dangerouslySetInnerHTML={{__html: this.props.summary}}></div>
        </a>
      </article>
    );
  }

}
