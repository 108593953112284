import React, { Component } from 'react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale'

export default class SliderItem extends Component {

  constructor(props) {
    super(props);
  }

  _renderDates(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    return format(startDate, 'd MMMM yyyy', {locale: fr})
      + (
        format(startDate, 'd MMMM yyyy') != format(endDate, 'd MMMM yyyy')
          ? '<br>> ' + format(endDate, 'd MMMM yyyy', {locale: fr})
          : ''
      );
  }

  render() {
    return (
      <div className={'event'}>
        <div
          className={'event__date font--color2'}
          dangerouslySetInnerHTML={{__html: this._renderDates(this.props.start_date, this.props.end_date)}}
        >
        </div>
        <div className={'event__content'}>
          <div className={'event__content__title'}>
            {this.props.title}
          </div>
          <div className={'event__content__body'} dangerouslySetInnerHTML={{__html: this.props.summary}}>
          </div>
        </div>
        <div className={'event__more'}>
          <a href={this.props.url}>
            <i className={'icon-arrow-next font--color2'}></i>
            <span>En savoir plus</span>
          </a>
        </div>
      </div>
    )
  }

}
