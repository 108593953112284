import React from 'react';

import CatalogItem from './CatalogItem.jsx';
import CatalogNoResult from './CatalogNoResult.jsx';
import AccordionItems from '../Accordion/AccordionItems.jsx';

export default class CatalogItems extends React.Component {

  constructor(props) {
    super(props);
  }

  _renderResults = () => {
    if (this.props.type == 'faq') {
      return <AccordionItems items={this.props.items}/>;
    }
    else if (this.props.type == 'menu-print') {
      return (
        <table className={'menu--print menu--nb--' + Object.keys(this.props.items).length}>
          <tbody>
            <tr>
              {
                Object.keys(this.props.items).map((index) => {
                  return (
                    <CatalogItem
                      key={index}
                      item={this.props.items[index]}
                      type={this.props.type}/>
                  );
                })
              }
            </tr>
          </tbody>
        </table>
      );
    }

    return Object.keys(this.props.items).map((index) => {
      return (
        <CatalogItem
          key={index}
          item={this.props.items[index]}
          type={this.props.type}/>
      );
    });
  };

  render() {
    if (this.props.items.length === 0) {
      return (
        <div className={'col-xs-12'}>
          {
            this.props.type == 'menu'
              ? <CatalogNoResult text={'Le menu du restaurant n\'est pas disponible.'}/>
              : <CatalogNoResult/>
          }
        </div>
      );
    }
    else {
      return this._renderResults();
    }
  }

}
