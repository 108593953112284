import React from 'react';
import InputField from '../TemplateElement/InputField.jsx';

export default class CheckboxRadioField extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    this.props.id = this.props.id || (this.props.name + '[' + this.props.value + ']');
    this.props.attributes = this.props.attributes || {};

    if (this.props.checked) {
      this.props.attributes.checked = 'checked';
    }
    else if (this.props.attributes.checked) {
      delete this.props.attributes.checked;
    }

    let {label,...other} = this.props;

    return (
      <div className={'form-check'}>
        <InputField{...other}/>
        <label className={'label-box ' + (this.props.type == 'radio' ? 'bg--after--color1' : 'font--after--color1')} htmlFor={this.props.id}>{label}</label>
      </div>
    );
  }

}
