import {getFirstDayOfCurrentWeek, getLastDayOfCurrentWeek} from '../../Utils/DateOperations.jsx';

export const APP_FILTER = {
  SEARCH: {
    INDEX: {
      exposed: false,
      solrFieldNames: [
        'index_id',
      ],
      values: [
        '*',
      ],
    },
    KEYWORD: {
      exposed: true,
      solrFieldNames: [
        'tm_X3b_fr_title',
        'tcphoneticm_X3b_fr_title',
        'tcngramm_X3b_fr_title',
        'tm_X3b_fr_body',
        'tcphoneticm_X3b_fr_body',
        'tcngramm_X3b_fr_body',
        'sm_news_tags',
        'sm_faq_tags',
        'ss_summary_processed',
        'tm_X3b_fr_field_p_accordion_body',
        'tcphoneticm_X3b_fr_field_p_accordion_body',
        'tcngramm_X3b_fr_field_p_accordion_body',
        'tm_X3b_fr_field_p_accordion_title',
        'tcphoneticm_X3b_fr_field_p_accordion_title',
        'tcngramm_X3b_fr_field_p_accordion_title',
        'tm_X3b_fr_field_p_content_body_1',
        'tcphoneticm_X3b_fr_field_p_content_body_1',
        'tcngramm_X3b_fr_field_p_content_body_1',
        'tm_X3b_fr_field_p_content_title_1',
        'tcphoneticm_X3b_fr_field_p_content_title_1',
        'tcngramm_X3b_fr_field_p_content_title_1',
        'tm_X3b_fr_filename',
        'tcphoneticm_X3b_fr_filename',
        'tcngramm_X3b_fr_filename',
        'tm_X3b_fr_field_p_resource_description',
        'tcphoneticm_X3b_fr_field_p_resource_description',
        'tcngramm_X3b_fr_field_p_resource_description',
      ],
    },
    TYPE: {
      exposed: true,
      solrFieldNames: [
        'ss_type',
      ],
      values: [
        '(basicpage OR landingpage)',
        'formation',
        'evenement',
        'news',
        'gallerie',
        'accesspage',
        //'faq', // done in CatalogWrapper
        //'file',
        //'homepage',
        //'menu',
      ],
    },
  },
  EVENT: {
    INDEX: {
      exposed: false,
      solrFieldNames: [
        'index_id',
      ],
      values: [
        'global_search',
      ],
    },
    TYPE: {
      exposed: false,
      solrFieldNames: [
        'ss_type',
      ],
      values: [
        'evenement',
      ],
    },
    END: {
      exposed: false,
      solrFieldNames: [
        'ds_end_value',
      ],
      values: [
        '[NOW TO *]',
      ],
    },
  },
  NEWS: {
    INDEX: {
      exposed: false,
      solrFieldNames: [
        'index_id',
      ],
      values: [
        'global_search',
      ],
    },
    TYPE: {
      exposed: false,
      solrFieldNames: [
        'ss_type',
      ],
      values: [
        'news',
      ],
    },
    TAGS: {
      exposed: true,
      solrFieldNames: [
        'sm_news_tags',
      ],
    },
  },
  FAQ: {
    INDEX: {
      exposed: false,
      solrFieldNames: [
        'index_id',
      ],
      values: [
        'global_search',
      ],
    },
    TYPE: {
      exposed: false,
      solrFieldNames: [
        'ss_type',
      ],
      values: [
        'faq',
      ],
    },
    KEYWORD: {
      exposed: true,
      solrFieldNames: [
        'tm_X3b_fr_title',
        'tcphoneticm_X3b_fr_title',
        'tcngramm_X3b_fr_title',
        'tm_X3b_fr_body',
        'tcphoneticm_X3b_fr_body',
        'tcngramm_X3b_fr_body',
      ],
    },
    TAGS:  {
      exposed: true,
      solrFieldNames: [
        'sm_faq_tags',
      ],
    },
  },
  GALLERY: {
    INDEX: {
      exposed: false,
      solrFieldNames: [
        'index_id',
      ],
      values: [
        'global_search',
      ],
    },
    TYPE: {
      exposed: false,
      solrFieldNames: [
        'ss_type',
      ],
      values: [
        'gallerie',
      ],
    },
  },
  MENU: {
    INDEX: {
      exposed: false,
      solrFieldNames: [
        'index_id',
      ],
      values: [
        'global_search',
      ],
    },
    TYPE: {
      exposed: false,
      solrFieldNames: [
        'ss_type',
      ],
      values: [
        'menu',
      ],
    },
    DATE: {
      exposed: true,
      solrFieldNames: [
        'ds_field_menu_date',
      ],
      values: [
        '[' + getFirstDayOfCurrentWeek(new Date()).toISOString() + ' TO ' + getLastDayOfCurrentWeek(new Date()).toISOString() + ']',
      ],
      neverEmpty: true,
    },
  },
  LANDING: {
    INDEX: {
      exposed: false,
      solrFieldNames: [
        'index_id',
      ],
      values: [
        'global_search',
      ],
    },
    URL: {
      exposed: false,
      solrFieldNames: [
        'sm_url',
      ],
      values: [],
    },
  },
};
