import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player';

class Video extends Component {

  render() {
    return(
      <>
        <ReactPlayer url={this.props.url}
                     light={true}
                     config={{
          youtube: {
            playerVars: {
              showinfo: 0,
              iv_load_policy: 1,
              playsinline: 0,
              controls: 1,
              cc_load_policy: 1,
              hl: 'fr',
              cc_lang_pref: 'fr',
            }
          }
        }}/>
      </>
    );
  }

}

export default Video;

const domContainer = document.querySelector('.video__player');
if (domContainer) {
  const url = domContainer.getAttribute('data-url');
  ReactDOM.render(<Video url={url}/>, domContainer);
}
