window.addEventListener("load", function () {
  var myTabs = document.querySelectorAll(".tab__link");
  function myTabClicks(tabClickEvent) {
    for (let i = 0; i < myTabs.length; i++) {
      myTabs[i].classList.remove("tab__link--active");
      myTabs[i].setAttribute('aria-selected', 'false');
      myTabs[i].setAttribute('tabindex', '-1');
    }
    const clickedTab = tabClickEvent.currentTarget;
    clickedTab.classList.add("tab__link--active");
    clickedTab.setAttribute('aria-selected', 'true');
    clickedTab.setAttribute('tabindex', '0');
    tabClickEvent.preventDefault();
    const contentPanes = document.querySelectorAll(".tab__pane");
    for (let i = 0; i < contentPanes.length; i++) {
      contentPanes[i].classList.remove("tab__pane--active");
    }
    const anchorReference = tabClickEvent.target;
    const activePaneId = anchorReference.getAttribute('data-tab');
    const activePane = document.querySelector(activePaneId);
    activePane.classList.add("tab__pane--active");
  }
  for (let i = 0; i < myTabs.length; i++) {
    myTabs[i].addEventListener("click", myTabClicks)
  }
});
