import React from 'react';

export default class AccordionItem extends React.Component {

  constructor(props) {
    super(props);
  }

  handleClick = (e) => {
    // Do not need for input
    e.preventDefault();

    this.props.handleClick(e);
  };

  render() {
    return (
      <div className={'accordion__item'}>
        <a className={'accordion__header'} href={'#'} aria-expanded={'false'} onClick={this.handleClick}>
          <h3 className={'block-content__title'} id={this.props.id}>
            {this.props.title}
          </h3>
        </a>
        <div className={'accordion__body'}>
          {
            typeof this.props.body === 'string'
              ? <div className={'accordion__content'} dangerouslySetInnerHTML={{__html: this.props.body}}></div>
              : <div className={'accordion__content'}>{this.props.body}</div>
          }
        </div>
      </div>
    );
  }

}
