import React from 'react';
import {format} from 'date-fns';
import {fr} from 'date-fns/locale';

export default class ViewmodeNews extends React.Component {

  constructor(props) {
    super(props);
  }

  _renderDate(startDate) {
    startDate = new Date(startDate);

    return (
      <div className={'news__date font--color1'}>
        Le {format(startDate, 'd MMMM yyyy', {locale: fr})}
      </div>
    );
  }

  _renderThumbnail(imageUri, title) {
    let attributes = {};
    if (imageUri) {
      attributes['style'] = {
        backgroundImage: 'url("' + imageUri + '")',
      };
    }
    return (
      <div className={'news__thumbnail bg--color1--lighten-50'}>
        {
          imageUri ?
            <div
              style={{backgroundImage: 'url("' + imageUri + '")'}}
            >
            </div>
            : <div className={'ghost'} aria-hidden={'true'}>
              {title}
            </div>
        }
      </div>
    );
  }

  _renderTag(tags) {
    if (!tags) {
      return '';
    }

    return (
      <div className={'news__content__bkg'}>
        <ul className={'horizontal category'}>
          {
            Object.keys(tags).map((index) => {
              return <li>{tags[index]}</li>;
            })
          }
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div className={'news__wrapper'}>
        <a className={'news__bloc'} href={this.props.url}>
          <div className={'news__top'}>
            {this._renderThumbnail(this.props.thumbnail_uri, this.props.title)}
          </div>
          <div className={'news__content'}>
            {this._renderDate(this.props.date)}
            {this._renderTag(this.props.tags)}
            <div className={'news__title'}>{this.props.title}</div>
            <div
              className={'news__summary'}
              dangerouslySetInnerHTML={{__html: this.props.summary}}
            >
            </div>
          </div>
        </a>
      </div>
    );
  }

}
