import React from 'react';
import ReactDOM from 'react-dom';

import {Fetcher} from '../../fetcher';
import * as CONSTANTS from '../../Utils/constants';
import Spinner from '../Spinner.jsx';

class EmbeddedMedia extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      status : CONSTANTS.APP_STATUS.LOADING,
      error : false,
      media_data : {},
    };

    this._fetchData();
  }

  _fetchData = () => {
    Fetcher.request('/media/image/' + this.props.uuid + '/' + this.props.imageStyle)
      .then((response) => {
        if (response.status === 'success') {
          this.setState({
            status : CONSTANTS.APP_STATUS.LOADED,
            media_data : response.query,
          });
        }
        else {
          this.setState({
            status : CONSTANTS.APP_STATUS.ERROR,
            media_data : {},
          });
        }
      });
  };

  render() {
    if (
      this.state.status === CONSTANTS.APP_STATUS.LOADED
      && typeof this.state.media_data.src != 'undefined'
    ) {
      let imgAttributes = {
        src : this.state.media_data.src,
        alt : this.props.alt || this.state.media_data.alt || null,
        title : this.state.media_data.title || null,
      };

      let legend = this.state.media_data.legend || '';

      return (
        <figure className={'content-image'}>
          <div style={{ 'background-image' : 'url(' + this.state.media_data.src + ')' }}>
            <img{...imgAttributes}/>
          </div>
          {
            legend
              ? <figcaption dangerouslySetInnerHTML={{__html: legend}}></figcaption>
              : ''
          }
        </figure>
      );
    }
    else if (this.state.status === CONSTANTS.APP_STATUS.LOADING) {
      return (
        <Spinner/>
      )
    }
    /*
    else if (this.state.status === CONSTANTS.APP_STATUS.ERROR) {
      return (
        <>
          <small>
            Une erreur s'est produite durant le chargement de l'image.
          </small>
          <br/>
          <br/>
        </>
      );
    }
    */

    return '';
  }
}

export default EmbeddedMedia;

const domContainers = document.querySelectorAll('.drupal-media');

Object.keys(domContainers).map((index) => {
  let domContainer = domContainers[index];
  let uuid = domContainer.getAttribute('data-entity-uuid');
  let alt = domContainer.getAttribute('alt');

  ReactDOM.render(<EmbeddedMedia uuid={uuid} alt={alt} imageStyle={'embed_wysiwyg_image'}/>, domContainer);
});
